import App from '../App'

const index = r => require.ensure([], () => r(require('./../views/Home')), 'home')

export default [{
  path: '/',
  hidden: true,
  component: App,
  children: [
    { path: '', redirect: '/index' },
    { path: '/', redirect: '/index' },
    {
      path: '/index',
      component: index,
      name: 'index',
    },
    // {
    //   path: '/index',
    //   component: index,
    //   name: 'index',
    //   meta: {
    //     pcWeb: 'index',
    //   }
    // },
  ],
}]
